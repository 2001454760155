export function getArticleCardControlsProps(
  props: ILhArticleCardProps,
): ILhArticleCardControlsProps {
  const {
    url,
    commentsEnabled,
    commentsCount,
    user,
    favorite,
    folders,
    isShowFavoriteFolderName,
    favoriteMenuOffsets,
    internalUrl,
  } = props;

  return {
    url,
    commentsEnabled,
    commentsCount,
    user,
    favorite,
    folders,
    isShowFavoriteFolderName,
    favoriteMenuOffsets,
    internalUrl,
  };
}

export async function onFavoriteAction() {
  // setTimeout чтобы safari успел отрисовать
  // hover у элемента под курсором
  await new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, 300);
  });
  const hoveredArticleCardsWithoutLast = Array.from(
    document.querySelectorAll(
      '.lh-small-article-card:hover, .lh-middle-article-card:hover, .lh-large-article-card:hover, .lh-large-article-card__wrapper:hover',
    ),
  ).slice(0, -1);
  if (hoveredArticleCardsWithoutLast.length) {
    hoveredArticleCardsWithoutLast.forEach((articleCard) => {
      articleCard.classList.add('lh-article-card--no-hover');
    });
  }
}
