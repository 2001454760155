<template>
  <div
    ref="container"
    class="lh-article-card-meta"
    :style="{ '--base-color': color, '--hover-color': hoverColor }"
  >
    <span
      v-if="isPromo"
      ref="category"
      class="lh-article-card-meta__promo lh-article-card-meta__text"
      data-jest="promo"
    >
      {{ partnerText }}
    </span>
    <span
      v-else-if="isAdvertising"
      ref="category"
      class="lh-article-card-meta__partner lh-article-card-meta__text"
      data-jest="partner"
    >
      {{ partnerText }}
    </span>
    <div
      v-else
      class="lh-article-card-meta__container"
    >
      <div class="lh-article-card-meta__text">
        <span
          v-show="dateText && dateVisible && isDateShowArticleCardSmall()"
          ref="date"
          class="lh-article-card-meta__date"
          data-jest="date"
        >
          {{ dateText }}
        </span>
      </div>
      <div
        v-for="(categoryItem, index) of computedCategories"
        :key="index"
        class="lh-article-card-meta__text"
      >
        <span
          v-if="isCircularLink(categoryItem) && !disableBreadcrumbCircularLink"
          ref="category"
          class="lh-article-card-meta__category"
        >
          {{ categoryItem.title }}
        </span>
        <span v-else>
          <TheLinkUi
            ref="category"
            class="lh-article-card-meta__category lh-article-card-meta__text"
            data-jest="category"
            :href="categoryItem.url"
            @click.native="$emit('clicked-on-category', categoryItem.url, categoryItem.title)"
          >
            {{ categoryItem.title }}
          </TheLinkUi>
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
  import type { PropType } from 'vue';

  import TheLinkUi from '@/components/lh-ui/TheLinkUi/TheLink.vue';

  const CATEGORIES_COUNT = 2;

  export default defineNuxtComponent({
    name: 'ArticleCardMeta',
    components: {
      TheLinkUi,
    },

    inject: {
      isDateShowArticleCardSmall: {
        default() {
          return () => true;
        },
      },
    },

    props: {
      disableBreadcrumbCircularLink: {
        type: Boolean,
        default: false,
      },
      dateText: {
        type: String as PropType<string>,
        required: false,
        default: '',
      },
      isPromo: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
      isAdvertising: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
      categories: {
        type: Array as PropType<Array<IArticleCategory>>,
        required: false,
        default: () => [],
      },
      categoriesCount: {
        type: Number as PropType<number>,
        required: false,
        default: CATEGORIES_COUNT,
      },
      color: {
        type: String as PropType<string>,
        required: false,
        default: '#5C5C5C',
      },
      hoverColor: {
        type: String as PropType<string>,
        required: false,
        default: 'black',
      },
      tags: {
        type: Array as PropType<Array<IArticleTags>>,
        required: false,
        default: () => [],
      },
      type: {
        type: String as PropType<string>,
        default: '',
      },
      partnerText: {
        type: String as PropType<string>,
        default: '',
      },
    },
    data() {
      return {
        container: {} as HTMLElement,
      };
    },
    computed: {
      isAds(): boolean {
        return this.isAdvertising || this.isPromo;
      },
      dateVisible(): boolean {
        return Boolean(this.dateText) && !this.isAds;
      },
      computedCategories(): IArticleCategory[] {
        // Отображать категории в том порядке, в котором приходит с бэка
        const breadCrumbs = this.categories.length ? this.categories : this.tags;
        return breadCrumbs.slice(0, this.categoriesCount);
      },
    },
    mounted() {
      this.container = this.$refs.container as HTMLElement;
    },
    methods: {
      isCircularLink(categoryItem: IArticleCategory): boolean {
        // @ts-ignore
        const path = this.$route?.path || '';

        return path.includes(categoryItem.url);
      },
    },
  });
</script>

<style lang="scss" src="./ArticleCardMeta.scss"></style>
