<template lang="pug">
  .lh-article-card-controls(
    :style="controlsStyles"
    :class="controlsClass"
  )
    FavoriteBlock.lh-article-card-controls__favorite(
      :user="user"
      :favorite="favorite"
      :folders="folders"
      :color="color"
      :hoverColor="hoverColor"
      :colorText="colorText"
      :colorTextHover="colorTextHover"
      :isShowFavoriteFolderName="isShowFavoriteFolderName"
      :favoriteMenuOffsets="favoriteMenuOffsets"
      @favorite-delete="onFavoriteDeleteEmit"
      @favorite-create-folder-and-add="onFavoriteCreateFolderAndAddEmit"
      @favorite-create-folder-and-transfer="onFavoriteCreateFolderAndTransferEmit"
      @favorite-transfer-to-folder="onFavoriteTransferToFolderEmit"
      @favorite-add-to-folder="onFavoriteAddToFolderEmit"
      @favorite-deferred="onFavoriteDeferredEmit"
      @favorite-action="onFavoriteAction"
      @open-favorite-menu="onOpenFavoriteMenu"
      @close-favorite-menu="onCloseFavoriteMenu"
    )

    TheLinkUi.lh-article-card-controls__comments(
      data-jest="comments"
      v-if="commentsEnabled"
      name="Открыть комментарии"
      :href="`${url}#comments`"
      @click.native="onClickComments(`${url}#comments`)"
    )
      include ../_assets/comment.svg
      span(v-show="isHasComments") {{commentsCount}}
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import TheLinkUi from '@/components/lh-ui/TheLinkUi/TheLink.vue';
  import FavoriteBlock from '@/components/lh-ui/FavoriteBlock/FavoriteBlock.vue';
  import FavoriteButton from '@/components/lh-ui/buttons/FavoriteButton.vue';

  export default defineNuxtComponent({
    name: 'ArticleCardControls',
    components: {
      FavoriteButton,
      FavoriteBlock,
      TheLinkUi,
    },
    props: {
      url: {
        type: String as PropType<string>,
        required: true,
      },
      commentsEnabled: {
        type: Boolean as PropType<boolean>,
        required: true,
      },
      commentsCount: {
        type: Number as PropType<number>,
        required: false,
        default: 0,
      },
      color: {
        type: String as PropType<string>,
        required: false,
        default: '#969698',
      },
      hoverColor: {
        type: String as PropType<string>,
        required: false,
        default: 'black',
      },
      colorText: {
        type: String as PropType<string>,
        required: false,
        default: '#5C5C5C',
      },
      colorTextHover: {
        type: String as PropType<string>,
        required: false,
        default: 'black',
      },
      user: {
        type: Object as PropType<IUser | null>,
        default: null,
      },
      favorite: {
        required: false,
        type: Object as PropType<IFavoriteItem | null>,
        default: null,
      },
      favoriteMenuOffsets: {
        type: Object as PropType<TOffsets | null>,
        default: null,
      },
      folders: {
        required: true,
        type: Object as PropType<IFolders>,
      },
      isShowFavoriteFolderName: {
        type: Boolean,
        default: false,
      },
      internalUrl: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
      },
    },
    data: () => ({
      activeCardControls: false,
    }),
    computed: {
      isHasComments(): boolean {
        return this.commentsCount > 0;
      },
      controlsClass(): any {
        return this.activeCardControls ? 'lh-article-card-controls--active' : null;
      },
      controlsStyles(): any {
        return {
          '--base-color': this.color,
          '--hover-color': this.hoverColor,
          '--base-color-text': this.colorText,
          '--hover-color-text': this.colorTextHover,
        };
      },
    },
    methods: {
      onOpenFavoriteMenu(): void {
        this.activeCardControls = true;
      },
      onCloseFavoriteMenu(): void {
        this.activeCardControls = false;
      },
      onFavoriteAction(): void {
        this.$emit('favorite-action');
      },
      onClickComments(link: string): void {
        this.$emit('clicked-on-comments', link);
      },
      onFavoriteDeferredEmit(deferredAction: Function, ctx: any) {
        this.$emit('favorite-deferred', deferredAction, ctx);
      },
      onFavoriteAddToFolderEmit(folder: IFolderItem) {
        this.$emit('favorite-add-to-folder', folder);
      },
      onFavoriteTransferToFolderEmit(favorite: IFavoriteItem, folder: IFolderItem) {
        this.$emit('favorite-transfer-to-folder', favorite, folder);
      },
      onFavoriteCreateFolderAndTransferEmit(nameFolder: string, favorite: IFavoriteItem): void {
        this.$emit('favorite-create-folder-and-transfer', nameFolder, favorite);
      },
      onFavoriteCreateFolderAndAddEmit(nameFolder: string): void {
        this.$emit('favorite-create-folder-and-add', nameFolder);
      },
      onFavoriteDeleteEmit(favorite: IFavoriteItem): void {
        this.$emit('favorite-delete', favorite);
      },
    },
  });
</script>

<style lang="scss" src="./ArticleCardControls.scss"></style>
