<template lang="pug">
  button.lh-button__favorite(
    :class="computedClass"
    aria-label="Добавить в избранное"
  )
    include ../_assets/favorite-icon.svg
</template>

<script lang="ts">
  import type { PropType } from 'vue';

  export default defineNuxtComponent({
    name: 'FavoriteButton',

    props: {
      checked: {
        type: Boolean as PropType<boolean>,
        required: true,
      },
    },

    computed: {
      computedClass(): { [propName: string]: boolean } {
        return {
          'lh-button__favorite_checked': this.checked,
        };
      },
    },
  });
</script>

<style lang="scss" src="./FavoriteButton.scss"></style>
